import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import TableElement from "../../../../components/table_element";
import classNames from "classnames";
import AlertDialog from "../../../../components/dialog_modal";
import {Tooltip} from "@mui/material";

const TableItem = ({
                       setNewScore,
                       index,
                       isOpen,
                       addNewScore,
                       listScore,
                       part = '',
                       base_mark = '',
                       difficulty_code = '',
                       bonus = '',
                       dd = '',
                       fc_element,
                       result_element = '',
                       coach_tech_controls,
                       coach_element,
                       element_id,
                       number,
                       isReferee,
                       dd_comment
                   }) => {

    const controls = (number) => {
        return coach_tech_controls?.find((f) => f?.number_coach === number) || {}
    }
    const coachElement = (number) => {
        return coach_element.find((f) => f.number_coach === number) || {}
    }
    const findIndexElement = (number) => {
        return coach_element.findIndex((f) => f.number_coach === number) || 0
    }


    const [coloredChoose, setColoredChoose] = useState({
        color_1: controls(1)?.value || '0',
        color_2: controls(2)?.value || '0',
        color_3: controls(3)?.value || '0',
        process_1: controls(1)?.value ? '0' : controls(1)?.value_process || '0',
        process_2: controls(2)?.value ? '0' : controls(2)?.value_process || '0',
        process_3: controls(3)?.value ? '0' : controls(3)?.value_process || '0',
    })

    const handlerClick = (variant, id) => {

        setColoredChoose({
            ...coloredChoose, [variant]:
            (coloredChoose[variant] === '0' && '1') ||
            (coloredChoose[variant] === '1' && '2') ||
            (coloredChoose[variant] === '2' && '3') ||
            '0'
        })
        setNewScore(id, (coloredChoose[variant] === '0' && '1') ||
            (coloredChoose[variant] === '1' && '2') ||
            (coloredChoose[variant] === '2' && '3') ||
            '0', false)
    }
    // console.log((findIndexElement(1) + 5))
    // console.log((findIndexElement(2) + 5))
    // console.log((findIndexElement(3) + 5))
    // console.log((findIndexElement(4) + 5))
    // console.log((findIndexElement(5) + 5))
    useEffect(() => {
        setColoredChoose({
            color_1: controls(1)?.value || 0,
            color_2: controls(2)?.value || 0,
            color_3: controls(3)?.value || 0,
            process_1: controls(1)?.value ? 0 : controls(1)?.value_process || 0,
            process_2: controls(2)?.value ? 0 : controls(2)?.value_process || 0,
            process_3: controls(3)?.value ? 0 : controls(3)?.value_process || 0,
        })
    }, [coach_tech_controls])

    return (
        <div className={s.item}>
            <div className={s.item_number}>
                {number}
            </div>
            <div className={s.elements_box}>
                <TableElement width={80} showTooltip={true}>{part}</TableElement>
                {/*<TableElement left={10} width={90} showTooltip={true}>{base_mark}</TableElement>*/}
                <TableElement left={10} width={180} showTooltip={true}>{difficulty_code}</TableElement>
                <TableElement left={10} width={118} showTooltip={true}>{bonus}</TableElement>
            </div>

            <TableElement disabled={!controls(1)?.id } className={classNames(
                s.colored_picker,
                coloredChoose.color_1 === '1' && s.green,
                coloredChoose.color_1 === '2' && s.red,
                coloredChoose.color_1 === '3' && s.yellow,
                coloredChoose.process_1 === '1' && s.green__process,
                coloredChoose.process_1 === '2' && s.red__process,
                coloredChoose.process_1 === '3' && s.yellow__process,
            )}
                onClick={() => handlerClick('color_1', controls(1)?.id)}
                          left={10}
                          width={36}>{null}</TableElement>
            <TableElement disabled={!controls(2)?.id } className={classNames(
                s.colored_picker,
                coloredChoose.color_2 === '1' && s.green,
                coloredChoose.color_2 === '2' && s.red,
                coloredChoose.color_2 === '3' && s.yellow,
                coloredChoose.process_2 === '1' && s.green__process,
                coloredChoose.process_2 === '2' && s.red__process,
                coloredChoose.process_2 === '3' && s.yellow__process,
            )}
                onClick={() => handlerClick('color_2', controls(2)?.id)}
                          left={10}
                          width={36}>{null}</TableElement>
            <TableElement disabled={!controls(3)?.id } className={classNames(
                s.colored_picker,
                coloredChoose.color_3 === '1' && s.green,
                coloredChoose.color_3 === '2' && s.red,
                coloredChoose.color_3 === '3' && s.yellow,
                coloredChoose.process_3 === '1' && s.green__process,
                coloredChoose.process_3 === '2' && s.red__process,
                coloredChoose.process_3 === '3' && s.yellow__process,
            )}
                onClick={() => handlerClick('color_3', controls(3)?.id)}
                          left={10}
                          width={36}>{null}</TableElement>


            <TableElement toFixed={3} left={10} width={55} isEdit={true} tooltipValue={dd_comment}
                          showTooltip
                          isDD={isReferee ? false : true}
                          disabled={isReferee}
                          onChange={(value) => setNewScore(`dd_${element_id}`, value)}
            >{dd}</TableElement>

            <TableElement toFixed={2} left={15} width={50}>{fc_element}</TableElement>

            <TableElement isOpen={isOpen} index={(findIndexElement(1) + 5)} toFixed={2} left={15} width={50}
                          isEdit={true} disabled={!coachElement(1)?.id || isReferee}
                          onChange={(value) => setNewScore(coachElement(1)?.id, value)}
                          setListScore={(value) => addNewScore(listScore, coachElement(1)?.id, value)}
                          >{coachElement(1)?.value}</TableElement>
            <TableElement isOpen={isOpen} index={(findIndexElement(2) + 5)} toFixed={2} left={10} width={50}
                          isEdit={true}
                          disabled={!coachElement(2)?.id || isReferee}
                          onChange={(value) => setNewScore(coachElement(2)?.id, value)}
                          setListScore={(value) => addNewScore(listScore, coachElement(2)?.id, value)}
                          >{coachElement(2)?.value}</TableElement>
            <TableElement isOpen={isOpen} index={(findIndexElement(3) + 5)} toFixed={2} left={10} width={50}
                          isEdit={true}
                          disabled={!coachElement(3)?.id || isReferee}
                          onChange={(value) => setNewScore(coachElement(3)?.id, value)}
                          setListScore={(value) => addNewScore(listScore, coachElement(3)?.id, value)}
                          >{coachElement(3)?.value}</TableElement>
            <TableElement isOpen={isOpen} index={(findIndexElement(4) + 5)} toFixed={2} left={10} width={50}
                          isEdit={true}
                          disabled={!coachElement(4)?.id || isReferee}
                          onChange={(value) => setNewScore(coachElement(4)?.id, value)}
                          setListScore={(value) => addNewScore(listScore, coachElement(4)?.id, value)}
                          >{coachElement(4)?.value}</TableElement>
            <TableElement isOpen={isOpen} index={(findIndexElement(5) + 5)} toFixed={2} left={10} width={50}
                          isEdit={true}
                          disabled={!coachElement(5)?.id || isReferee}
                          onChange={(value) => setNewScore(coachElement(5)?.id, value)}
                          setListScore={(value) => addNewScore(listScore, coachElement(5)?.id, value)}
                          >{coachElement(5)?.value}</TableElement>

            <TableElement toFixed={4} left={10} width={90}>{result_element}</TableElement>
        </div>
    );
};

export default TableItem;
