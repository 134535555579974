import CloseIcon from '@mui/icons-material/Close';
import classNames from "classnames";
import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import api from "../../../../api/api";
import s from './styles.module.css';

const TableMain = ({chooseDistipline, ageGroupSelect}) => {
    const [data, setData] = useState([])
    const [limit, setLimit] = useState(0)
    const [formirationData, setFormirationData] = useState(null)
    const [pos, setPos] = useState(0)
    const [isShow, setIsShow] = useState(false)

    const formirationFunc = async () => {

        try {
            const sort_data = data?.filter(f => f.pos && f?.pos !== 'null')?.sort((a, b) => +a?.pos - +b?.pos)

            if (sort_data?.length === 0) {
                toast.warning('Выберите тип отчета')
            } else {
                const res = await api().post(`/reports/create`, {
                    report_id: sort_data?.map((el) => el?.report_id),
                    age_group_report_id: ageGroupSelect,
                    discipline_report_id: chooseDistipline,
                    format: 'pdf',
                }, {
                    responseType: 'arraybuffer',
                })
                if (!res.data) {
                    toast.warning('Нет данных')
                    setFormirationData(null)
                } else {
                    const objectURL = URL.createObjectURL(
                        new Blob([res.data], {type: 'application/pdf'})
                    );
                    setFormirationData(objectURL)

                    // debugger
                }
            }
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
            setFormirationData(null)
        }
    }
    const downloadAsPdf = async () => {
        try {
            const sort_data = data?.filter(f => f.pos && f?.pos !== 'null')?.sort((a, b) => +a?.pos - +b?.pos)

            if (sort_data?.length === 0) {
                toast.warning('Выберите тип отчета')
            } else {
                const res = await api().post(`/reports/create`, {
                    report_id: sort_data?.map((el) => el?.report_id),
                    age_group_report_id: ageGroupSelect,
                    discipline_report_id: chooseDistipline,
                    format: 'pdf',
                }, {
                    responseType: 'arraybuffer',
                })
                if (!res.data) {
                    toast.warning('Нет данных')
                    setFormirationData(null)
                } else {
                    const blob = new Blob([res.data], {type: 'application/pdf'});

                    // Создаем ссылку для скачивания файла
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'отчет.pdf';

                    // Добавляем ссылку в DOM и автоматически симулируем клик для скачивания файла
                    document.body.appendChild(link);
                    link.click();

                    // Удаляем ссылку из DOM
                    document.body.removeChild(link);
                    // debugger
                }
            }
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
            setFormirationData(null)
        }
    }
    const downloadAsExcel = async () => {
        try {
            const sort_data = data?.filter(f => f.pos && f?.pos !== 'null')?.sort((a, b) => +a?.pos - +b?.pos)

            if (sort_data?.length === 0) {
                toast.warning('Выберите тип отчета')
            } else {
                const res = await api().post(`/reports/create`, {
                    report_id: sort_data?.map((el) => el?.report_id),
                    age_group_report_id: ageGroupSelect,
                    discipline_report_id: chooseDistipline,
                    format: 'excel',
                }, {
                    responseType: 'arraybuffer',
                })
                if (!res.data) {
                    toast.warning('Нет данных')
                    setFormirationData(null)
                } else {
                    const blob = new Blob([res.data], {type: 'application/xlsx'});

                    // Создаем ссылку для скачивания файла
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'отчет.xlsx';

                    // Добавляем ссылку в DOM и автоматически симулируем клик для скачивания файла
                    document.body.appendChild(link);
                    link.click();

                    // Удаляем ссылку из DOM
                    document.body.removeChild(link);
                }
            }
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
            setFormirationData(null)
        }
    }

    useEffect(() => {
        const getList = async () => {
            try {
                const res = await api().get(`reports/tpl`)
                setData(res?.data?.reports?.sort((a, b) => a?.sort - b?.sort))
                setLimit(res?.data?.reports?.length)
            } catch (e) {
                console.log(e)
            }
        }

        const getIsShow = async () => {
            const res = await api().get(`is_show_excel`);
            setIsShow(res?.data?.is_show_excel);
        }

        getIsShow()
        getList()
    }, [])

    return (
        <div className={s.content}>
            <div className={s.content_left}>
                <div className={s.header}>
                    <div className={s.btn_icon} onClick={() => {
                        setPos(0)
                        setData(data?.map((el) => ({...el, pos: 'null'})))

                    }}>
                        <CloseIcon/>
                    </div>
                    <p>Типы отчетов</p>
                </div>

                <div className={s.content_left_items}>
                    {data?.map((item, i) => {

                        return <div key={item?.report_id} className={s.item}>
                            <div className={classNames(s.item_item, s.item_item_f)} style={{marginLeft: 0}}
                                 onClick={() => {
                                     if (!item?.pos || item?.pos === 'null') {
                                         console.log(data)
                                         const newPos = data.filter(el => el.pos !== null && el.pos !== 'null' && el.pos).length + 1;
                                         setPos(newPos);
                                         setData(data.map((el) =>
                                             +el.report_id === +item?.report_id ? {...el, pos: newPos} : el
                                         ));
                                     } else {
                                         const deletedPos = item.pos;
                                         setData(data
                                             .map(el => {
                                                 if (+el.report_id === +item?.report_id) {
                                                     return {...el, pos: null};
                                                 }
                                                 return el;
                                             })
                                             .map(el => {
                                                 if (el.pos > deletedPos) {
                                                     return {...el, pos: el.pos - 1};
                                                 }
                                                 return el;
                                             }));
                                     }
                                 }}>
                                {!item?.pos || item?.pos === 'null' ? '-' : item?.pos}
                            </div>

                            <div className={s.item_item}>{item?.report_name}</div>
                        </div>
                    })}
                </div>
            </div>
            <div className={s.content_right}>
                <div className={s.pdf_viewer}>
                    <iframe
                        src={formirationData}
                        title="PDF Viewer"
                        width="100%"
                        style={{
                            minHeight: '57vh'
                        }}
                    />
                </div>

                <div className={s.actions}>
                    <div className={s.btn} onClick={formirationFunc}>Сформировать</div>
                    <div className={s.btn} onClick={downloadAsPdf}>PDF</div>
                    <div className={s.btn} onClick={downloadAsExcel}
                         style={{visibility: isShow === false ? 'hidden' : 'visible'}}>Excel
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableMain;
